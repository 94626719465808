import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './root.html',
  styleUrls: ['./root.sass']
})
export class Root {
  title = 'quick-order';
}
