import { DEFAULT_CURRENCY_CODE, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RootRoutingModule } from './root.routing.module';
import { Root } from './root';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { TranslocoModule } from '@ngneat/transloco';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { TranslocoRootModule } from './core/modules/transloco.root.module';
import { BidiModule } from '@angular/cdk/bidi';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './core/material.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ar_EG, de_DE, en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { FacebookModule } from 'ngx-facebook';

registerLocaleData(de, 'de');

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {};
}
@NgModule({
  declarations: [
    Root
  ],
  imports: [
    FacebookModule.forRoot(),
    BrowserModule,
    RootRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyDTwVLGCyzqAeyclnS1qAucdN14id5VWPA',
      authDomain: 'quick-order-de.firebaseapp.com',
      projectId: 'quick-order-de',
      storageBucket: 'quick-order-de.appspot.com',
      messagingSenderId: '796858040075',
      appId: '1:796858040075:web:da9f17f11ba888831bac0e'
    }),
    AngularFirestoreModule,
    AngularFirestoreModule.enablePersistence({synchronizeTabs: true}),
    AngularFireAuthModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    HttpClientModule,
    TranslocoModule,
    TranslocoRootModule,
    BidiModule,
    HammerModule,
    ReactiveFormsModule,
    MaterialModule,
    NzMessageModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          case 'de':
            return de_DE;
          default:
            return ar_EG;
        }
      },
      deps: [LOCALE_ID]
    }
  ],
  bootstrap: [Root]
})
export class RootModule { }
